//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownPaginatedModel } from "../../pojo/DropDownPaginatedModel";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Defer, jSith } from "../../util/jquery-replacement";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { InfiniteScrollApi } from "../s25-infinite-scroll/infinite.scroll.api";
import { Api } from "../../api/api";
import { MultiselectResultsApi } from "./s25.multiselect.results.component";
import { MultiselectPopupApi } from "./s25.multiselect.popup.component";
import { DropDownItem } from "../../pojo/DropDownItem";
import { Bind } from "../../decorators/bind.decorator";

export class MultiselectApi extends Api {
    static refresh(relativeElem: HTMLElement, uuid: string) {
        return MultiselectApi.callApiFn(relativeElem, "s25-ng-multiselect", "initComp", null, null, (comp, i) => {
            return comp && comp.modelBean && comp.modelBean.uuid === uuid;
        });
    }

    static forceScrollAction(relativeElem: HTMLElement, uuid: string) {
        return MultiselectApi.callApiFn(
            relativeElem,
            "s25-ng-multiselect",
            "forceScrollAction",
            null,
            null,
            (comp, i) => {
                return comp && comp.modelBean && comp.modelBean.uuid === uuid;
            },
        );
    }
}

export interface MultiselectDomainFilterI {
    options?: S25ItemI[]; //list of filters from which to choose (itemId used with prefix, itemName is display value)

    //WS param like "&query_id="
    //this is combined with chosen.itemId and used in filterMethod, which has the whole domainFilter in this
    prefix?: string;

    filterMethod: Function; //must return promise
    promise?: Promise<any>; //set by component: result of filterMethod
    chosen?: any; //set by component: chosen filter
}

export interface MultiselectCustomFilterI {
    isPushed?: boolean;
    label?: string;
    action?: (model: MultiselectModelI) => any;
}

export interface MultiselectModelI {
    items?: S25ItemI[];
    uuid?: string;
    origItems?: S25ItemI[]; //set by component
    selectedItems?: S25ItemI[]; //set by component
    uncommittedSelectedItems?: S25ItemI[]; //set by component and only used if hasCommit is true,
    removedItems?: S25ItemI[]; //set by component,
    addedItems?: S25ItemI[]; //set by component,
    title?: string;
    expensiveSearch?: boolean; //indicates if user must search to populate multiselect bc too many items could exist to pre-populate
    hasFilter?: boolean; //if user can filter results
    hasTags?: boolean; //If the user can filter on SystemTags
    hasCategories?: boolean;
    domainFilter?: MultiselectDomainFilterI;
    customFilter?: MultiselectCustomFilterI[];
    omitReadOnly?: boolean;
    customFilterValue?: string;
    showFavorite?: boolean;
    showResult?: boolean;
    showMatching?: boolean;
    filterFavorites?: boolean;
    singleSelect?: boolean;
    hasSelectAll?: boolean;
    hasSelectNone?: boolean;
    hasDone?: boolean;
    hasCommit?: boolean;
    hasCancel?: boolean;
    hasQuantity?: boolean;
    hasScroll?: boolean;
    noSort?: boolean;
    preFetchItems?: boolean;
    isItemsInit?: boolean;
    isSearchCriteria?: boolean;
    commitErrorMsg?: string;
    addSelectedItem?: (item: S25ItemI) => any; //set by component
    removeSelectedItem?: (item: S25ItemI) => any; //set by component
    selectNone?: () => any;
    clearSearch?: () => any;
    serverSideFilter?: (searchTerm: string) => Promise<S25ItemI[]>;
    page?: number;
    pageCount?: number;
    pageSize?: number;
    cacheId?: number;
    filter?: any;
    hasMoreF?: () => boolean;
    scrollF?: Function;
    dataPromise?: Promise<any>;
    dataDefer?: Defer;
    removeAction?: Function;
    addAction?: Function;
    onInit?: () => Promise<any>;
    onCommit?: Function;
    onDone?: () => void;
    onChange?: Function;
    action?: Function;
    matching?: "any" | "all";
    onMatchingChange?: (matching: "any" | "all") => any;
    extractItems?: (data: any) => S25ItemI[]; // Custom client side filtering of fetched items
    serviceMethod?: Function;
    currentPromises?: Promise<any>[];
    popoverTemplate?: TemplateRef<any>;
    popoverClass?: string;
    onShow?: Function; //on popover shown if multiselect is in popover
    onHide?: Function; //on popover hide if multiselect is in popover
    forceClosePopup?: Function; //set by popover component if multiselect is in popover
    done?: Function; //set by component for outsiders to call
    cancel?: Function; //set by component for outsiders to call
    closing?: boolean; //if popover being closed manually
    isInline?: boolean; //if popover button is going to be inline next to other elements (if true, need to add a div above it with ngRelative class)
    textButton?: boolean; //if popover button should look like a text button vs regular outline button
    buttonText?: string; //optional text for the button
    placeholder?: string; //if adding input placeholder at smaller screen sizes to avoid label clutter
    buttonClass?: string; // optional css for the button,  such as online, primary
    usePopover?: boolean; //option to render multiselect with or without popover
}

@TypeManagerDecorator("s25-ng-multiselect")
@Component({
    selector: "s25-ng-multiselect",
    template: `
        @if (!modelBean.usePopover && !modelBean.domainFilter) {
            <div class="no-popover-spinner">
                <s25-loading-inline [model]="{}"></s25-loading-inline>
            </div>
        }
        @if (this.isInit) {
            <div class="s25-ng s25-multiselect-popup-container" [id]="this.id">
                <div class="s25-header tb-body">
                    <div class="tb-col header-content">
                        <div class="s25-title c-margin-right--quarter">{{ this.modelBean.title }}</div>
                        @if (this.hasItems || this.modelBean.expensiveSearch) {
                            <div class="s25-grp">
                                <div class="s25-grp">
                                    @if (this.modelBean.hasFilter) {
                                        <div class="s25-search c-seriesQL-multiselect--input">
                                            <input
                                                [(ngModel)]="this.searchTerm"
                                                (ngModelChange)="this.searchByTerm()"
                                                class="c-input"
                                                type="text"
                                                [attr.aria-label]="'filter'"
                                                [placeholder]="modelBean.placeholder || ''"
                                            />
                                            <s25-loading-inline [model]="{}"></s25-loading-inline>
                                        </div>
                                    }
                                    @if (modelBean.domainFilter && dropdownModel) {
                                        <div class="ngInlineBlock ngDomainFilterContainer c-margin-left--half">
                                            <s25-dropdown-paginated
                                                [model]="this.dropdownModel"
                                                [onSelect]="this.searchByTerm"
                                                [(chosen)]="this.modelBean.domainFilter.chosen"
                                                [choice]="choice"
                                            >
                                            </s25-dropdown-paginated>
                                            <ng-template #choice let-item="item">
                                                <div [innerHTML]="item.itemName">
                                                    @if (item.isInactive === 1) {
                                                        <span class="not_active"> [Not Active] </span>
                                                    }
                                                </div>
                                            </ng-template>
                                        </div>
                                    }
                                    <div class="ngInlineBlock c-margin-left--quarter">
                                        @if (modelBean.hasTags) {
                                            <s25-ng-dropdown-multi-search-criteria
                                                [(chosen)]="chosenTags"
                                                (chosenChange)="searchByTerm()"
                                                [type]="'systemTags'"
                                                [placeholder]="'Filter By Tag'"
                                            ></s25-ng-dropdown-multi-search-criteria>
                                        }
                                        @if (modelBean.hasCategories) {
                                            <s25-ng-dropdown-multi-search-criteria
                                                [(chosen)]="chosenCategories"
                                                (chosenChange)="searchByTerm()"
                                                [type]="'securityGroups'"
                                                [placeholder]="'Filter By Category'"
                                            ></s25-ng-dropdown-multi-search-criteria>
                                        }
                                    </div>
                                    <div class="aw-button-group c-margin-right--double">
                                        @if (this.modelBean.showFavorite) {
                                            <button
                                                (click)="this.toggleFavorite()"
                                                class="btn ngToggleFavoriteBtn aw-button--outline aw-button {{
                                                    this.modelBean.filterFavorites ? 'ngToggleBtnPushed' : ''
                                                }}"
                                            >
                                                <svg class="c-svgIcon c-svgIcon--small" role="img">
                                                    <title>Favorites</title>
                                                    <use
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#star--star-filled"
                                                    ></use>
                                                </svg>
                                                Only Favorites
                                            </button>
                                        }
                                        @if (!this.modelBean.singleSelect && this.modelBean.hasSelectAll) {
                                            <button
                                                class="btn aw-button aw-button--outline c-margin-right--half"
                                                (click)="this.selectAll()"
                                            >
                                                <svg class="c-svgIcon" role="img">
                                                    <title>Select All</title>
                                                    <use
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                                    ></use>
                                                </svg>
                                                Select All
                                            </button>
                                        }
                                        @if (this.modelBean.hasSelectNone) {
                                            <button
                                                class="btn aw-button aw-button--outline"
                                                (click)="this.selectNone()"
                                            >
                                                <svg class="c-svgIcon" role="img">
                                                    <title>Select None</title>
                                                    <use
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                                    ></use>
                                                </svg>
                                                Select None
                                            </button>
                                        }
                                    </div>
                                    @if (this.modelBean.customFilter) {
                                        <div class="s25-search">
                                            @for (
                                                filter of this.modelBean.customFilter;
                                                track this.trackByFn($index, filter)
                                            ) {
                                                <button
                                                    (click)="filter.action(modelBean)"
                                                    class="s25-btn btn {{
                                                        filter.isPushed ? 'ngToggleBtnPushed' : ''
                                                    }} aw-button--outline aw-button"
                                                >
                                                    {{ filter.label }}
                                                </button>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div
                        class="c-button c-button--flat c-button--close"
                        (click)="this.done()"
                        (keyup.enter)="this.done()"
                        tabindex="0"
                    >
                        <svg class="c-svgIcon" role="img">
                            <title>Close</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                            ></use>
                        </svg>
                        <span class="visually-hidden">Close menu</span>
                    </div>
                </div>
                <div
                    class="s25-multiselect-columns-container"
                    s25-infinite-scroll
                    [onScroll]="this.modelBean.scrollF"
                    [hasMorePages]="this.modelBean.hasMoreF"
                    topSelector="self"
                    [ready]="this.modelBean.dataPromise"
                >
                    <div
                        class="s25-multiselect-columns {{
                            this.modelBean.hasQuantity ? 's25-multiselect-columns-quantity' : ''
                        }}"
                    >
                        @for (item of this.modelBean.items; track this.trackByFn($index, item)) {
                            <div [hidden]="item.isSecret" class="s25-multiselect-infinite-scroll-item">
                                @if (this.modelBean.singleSelect) {
                                    <s25-ng-multiselect-item
                                        type="radio"
                                        [modelBean]="this.modelBean"
                                        [item]="item"
                                        [autoFocus]="!this.modelBean.hasFilter"
                                    ></s25-ng-multiselect-item>
                                }
                                @if (!this.modelBean.singleSelect) {
                                    <s25-ng-multiselect-item
                                        type="check"
                                        [modelBean]="this.modelBean"
                                        [item]="item"
                                        [autoFocus]="!this.modelBean.hasFilter"
                                    ></s25-ng-multiselect-item>
                                }
                            </div>
                        }
                        @if (!this.hasItems && !this.modelBean.expensiveSearch) {
                            <s25-ng-multiselect-item type="none"></s25-ng-multiselect-item>
                        }
                    </div>
                </div>
                <div class="s25-multiselect-footer">
                    <div class="tb-col footer-col-left"></div>
                    <div class="aw-button-group">
                        @if (this.modelBean.hasCommit && this.modelBean.hasCancel) {
                            <button class="aw-button aw-button--outline" (click)="this.cancel()">Cancel</button>
                        }
                        @if (this.modelBean.hasDone) {
                            <button class="aw-button aw-button--primary" (click)="this.done()">Done</button>
                        }
                    </div>
                    @if (this.modelBean.commitErrorMsg) {
                        <div class="s25-error">{{ this.modelBean.commitErrorMsg }}</div>
                    }
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MultiselectComponent implements OnInit {
    @Input() modelBean: MultiselectModelI;
    @Output() modelBeanChange: EventEmitter<MultiselectModelI> = new EventEmitter();

    isInit: boolean = false;
    hasItems: boolean;
    searchTerm: string;
    dropdownModel: DropDownPaginatedModel;
    dirty: boolean = false;
    chosenTags: DropDownItem[] = [];
    chosenCategories: DropDownItem[] = [];

    static count: number = 0;
    id: string;

    /*

     */
    searchByTerm = () => {
        let searchTerm = S25Util.toStr(this.searchTerm).toLowerCase();
        let startItems = this.modelBean.origItems;
        if (this.chosenTags?.length > 0) {
            this.searchByTag();
            startItems = this.modelBean.items;
        }

        if (this.chosenCategories?.length > 0) {
            this.searchByCategory();
            startItems = this.modelBean.items;
        }

        if (this.modelBean.hasScroll) {
            //if this is an expensive search that is empty, then just show selected items
            if (
                this.modelBean.expensiveSearch &&
                (!this.searchTerm || this.searchTerm.length === 0) && //empty search term
                !this.modelBean.filterFavorites && //not filtering favorites
                !this.modelBean.customFilterValue && //no custom filter value
                //no active domain filter (-999 means "All" so it is not an active filter)
                (!this.modelBean.domainFilter ||
                    !this.modelBean.domainFilter.chosen ||
                    this.modelBean.domainFilter.chosen.itemId == -999)
            ) {
                //code below reverts items shown to selected items list, falling back to empty array...
                this.modelBean.items = S25Util.array.clone(this.getSelectedItems()) || [];
                this.refresh();
                return jSith.when(this.modelBean.items);
            } else {
                S25LoadingApi.init(this.elementRef.nativeElement); //show loading for expensive search
                return this.modelBean.serverSideFilter(this.searchTerm).then(
                    (items) => {
                        //run server side filter
                        if (items) {
                            this.refreshSelected(items); //check new items, if needed
                            this.modelBean.items = items;
                            S25LoadingApi.destroy(this.elementRef.nativeElement); //hide loading after processing
                            this.refresh();
                            return jSith.when(this.modelBean.items); //return in promise (set-repeat uses this)
                        } else {
                            S25LoadingApi.destroy(this.elementRef.nativeElement); //hide loading after processing
                            this.refresh();
                            return jSith.when();
                        }
                    },
                    () => {
                        S25LoadingApi.destroy(this.elementRef.nativeElement);
                        this.refresh();
                        return jSith.when();
                    },
                );
            }
        } else {
            this.modelBean.items = S25Util.array.clone(
                startItems.filter((item) => {
                    let itemString = S25Util.toStr(item.itemName).toLowerCase();
                    if (itemString.indexOf(searchTerm) > -1) {
                        //empty search term will include all items
                        if (this.modelBean.filterFavorites) {
                            return S25Util.toBool(item.isFav);
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                }),
            );
            this.refreshSelected(this.modelBean.items);
        }
    };

    searchByCategory() {
        let items: any[] = [];
        this.chosenCategories.forEach((category) => {
            items = [...items, ...category.security_groups];
        });

        let uniqueItems: any[] = [];
        items.forEach((item: any) => {
            const found = uniqueItems.find((el) => el.security_group_id === item.security_group_id);
            if (!found) uniqueItems.push(item);
        });

        this.modelBean.items = uniqueItems.map((item: any) => {
            return {
                itemName: item.security_group_name,
                itemId: parseInt(item.security_group_id),
                status: item.status,
                checked: item.checked,
            };
        });

        this.modelBean.items.sort((a, b) => {
            const nameA = a.itemName.toLowerCase();
            const nameB = b.itemName.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });

        this.refreshSelected(this.modelBean.items);
    }

    /*
    Filters against any visible items. If an item has ANY tag it will be selected.
    When no tags are selected reset to original list
     */
    @Bind
    searchByTag(skipRefresh = true) {
        if (this.chosenTags?.length > 0) {
            this.modelBean.items = S25Util.array.clone(
                this.modelBean.origItems.filter((item) => {
                    for (let i = 0; i < this.chosenTags.length; i++) {
                        if (
                            S25Util.array.isIn(S25Util.array.forceArray(item.tags), "tagId", this.chosenTags[i].itemId)
                        ) {
                            return true;
                        }
                    }
                }),
            );
        } else {
            //reset to origial items
            this.modelBean.items = S25Util.array.clone(this.modelBean.origItems);
        }

        if (!skipRefresh) {
            this.refreshSelected(this.modelBean.items);
        }
    }

    clearSearch = () => {
        this.modelBean.page = 0;
        this.modelBean.cacheId = 0;
        this.modelBean.filter = undefined;
        this.modelBean.filterFavorites = false;
        this.searchTerm = "";
        if (this.modelBean.domainFilter && this.modelBean.domainFilter.options) {
            this.modelBean.domainFilter.chosen = this.modelBean.domainFilter.options[0]; //All filter...
        }
        this.modelBean.customFilterValue = ""; //reset any custom filter query params that were added...
        if (this.modelBean.customFilter) {
            //reset any custom filter buttons to unpushed...
            jSith.forEach(this.modelBean.customFilter, (_: any, filter: MultiselectCustomFilterI) => {
                filter.isPushed = false;
            });
        }
        this.modelBean.items = S25Util.array.clone(this.modelBean.origItems);
        this.refreshSelected(this.modelBean.items);
    };

    getSelectedItems = (): S25ItemI[] => {
        return this.modelBean.hasCommit ? this.modelBean.uncommittedSelectedItems : this.modelBean.selectedItems;
    };

    refreshSelected = (items: S25ItemI[]) => {
        if (items && items.length) {
            let selectedItems = this.getSelectedItems();
            jSith.forEach(items, (_: any, item: S25ItemI) => {
                if (S25Util.array.isIn(selectedItems, "itemId", item.itemId)) {
                    //if item comes in checked or is pre-loaded to the checked list, set it
                    this.setSelected(item, true);
                } else {
                    this.setUnselected(item, true);
                }
            });
            this.sortSelectedItemsByItemName(true);
        }
        this.refresh();
    };

    toggleFavorite = () => {
        let newValue = !this.modelBean.filterFavorites; //toggle search fav
        this.clearSearch();
        this.modelBean.filterFavorites = newValue;
        return this.searchByTerm();
    };

    addAllPaginatedItems = () => {
        if (this.modelBean.hasScroll) {
            if (
                (this.searchTerm && this.searchTerm.length > 0) ||
                this.modelBean.filterFavorites ||
                this.modelBean.customFilterValue ||
                (this.modelBean.domainFilter &&
                    this.modelBean.domainFilter.chosen &&
                    [-999].indexOf(this.modelBean.domainFilter.chosen.itemId) === -1)
            ) {
                if (this.modelBean.hasMoreF()) {
                    S25LoadingApi.init(this.elementRef.nativeElement);
                    let data = { defer: jSith.defer() };
                    InfiniteScrollApi.addAllPages(this.elementRef.nativeElement, data);
                    return data.defer.promise.then(() => {
                        return setTimeout(() => {
                            S25LoadingApi.destroy(this.elementRef.nativeElement);
                        }, 250);
                    });
                } else {
                    return jSith.when();
                }
            } else {
                return jSith.when();
            }
        }
        return jSith.when();
    };

    setSelected = (item: S25ItemI, skipDetectChanges?: boolean) => {
        item.checked = true;
        let selectedItems = this.getSelectedItems();
        let notFound = S25Util.array.inplacePushByProp(selectedItems, "itemId", item.itemId, item);
        notFound && S25Util.array.inplacePushByProp(this.modelBean.addedItems, "itemId", item.itemId, item);
        S25Util.array.inplaceRemoveByProp(this.modelBean.removedItems, "itemId", item.itemId);

        let selectedItem = S25Util.array.getByProp(selectedItems, "itemId", item.itemId);
        item.isPermanent = selectedItem.isPermanent;

        //keep quantity
        if (this.modelBean.hasQuantity) {
            item.quantity = selectedItem.quantity;
            item.quantitySrc = item.quantity;
        }

        if (!skipDetectChanges) {
            this.refresh();
            this.dirty = true;
        }
    };

    setUnselected = (item: S25ItemI, skipDetectChanges?: boolean) => {
        if (!item.isPermanent) {
            item.checked = false;
            let selectedItems = this.getSelectedItems();
            if (selectedItems.length > 0) {
                for (let i = selectedItems.length - 1; i >= 0; i--) {
                    //we need a nondestructive delete to maintain ref to calling directive, potentially
                    if (selectedItems[i].itemId == item.itemId) {
                        selectedItems[i].checked = false;

                        if (this.modelBean.hasQuantity) {
                            item.quantity = undefined;
                            item.quantitySrc = undefined;
                            selectedItems[i].quantity = undefined;
                            selectedItems[i].quantitySrc = undefined;
                        }

                        selectedItems.splice(i, 1);
                        S25Util.array.inplaceRemoveByProp(this.modelBean.addedItems, "itemId", item.itemId);
                        S25Util.array.inplacePushByProp(this.modelBean.removedItems, "itemId", item.itemId, item);
                        break;
                    }
                }
            }

            if (!skipDetectChanges) {
                this.refresh();
                this.dirty = true;
            }
        }
    };

    sortSelectedItemsByItemName = (skipDetectChanges?: boolean) => {
        !this.modelBean.noSort && this.getSelectedItems().sort(S25Util.shallowSort("itemName"));
        !skipDetectChanges && this.refresh();
    };

    selectAll = () => {
        this.addAllPaginatedItems().then(() => {
            jSith.forEach(this.modelBean.items, (_: any, item) => {
                this.setSelected(item, true);
            });
            this.sortSelectedItemsByItemName(true);
            this.refresh();
            this.dirty = true;
        });
    };

    selectNone = () => {
        let selectedItems = this.getSelectedItems();
        for (let i = selectedItems.length - 1; i >= 0; i--) {
            this.setUnselected(selectedItems[i], true);
        }
        for (let item of this.modelBean.items) this.setUnselected(item, true); // Make sure all items are unselected
        this.refresh();
        this.dirty = true;
    };

    done = (skipPopupClose?: boolean) => {
        if (this.modelBean.hasScroll) {
            //reset scroll data promise so scroll waits for elements to start its polling and caching of elements
            InfiniteScrollApi.clear(this.elementRef.nativeElement);
            this.modelBean.dataDefer = jSith.defer();
            this.modelBean.dataPromise = this.modelBean.dataDefer.promise;
        }
        this.clearSearch(); //clear search

        if (this.modelBean.hasCommit) {
            this.modelBean.selectedItems = S25Util.array.clone(this.modelBean.uncommittedSelectedItems);
        }

        this.getSelectedItems().map(function (item) {
            S25Util.extend(item, {
                quantitySrc: item.quantity,
                checked: true,
            });
        });
        this.modelBean.action && this.modelBean.action();
        this.dirty && this.modelBean.onChange && this.modelBean.onChange();
        this.modelBean.onDone && this.modelBean.onDone();
        let resp: any =
            (this.modelBean.hasCommit && this.modelBean.onCommit && this.modelBean.onCommit()) || jSith.when();
        resp &&
            resp.then &&
            resp.then(
                () => {
                    MultiselectResultsApi.refresh(document.body, this.modelBean.uuid);
                    !skipPopupClose && MultiselectPopupApi.close(document.body, this.modelBean.uuid);
                },
                (err: any) => {
                    this.modelBean.commitErrorMsg = S25Util.errorText(err);
                },
            );
        this.dirty = false;
        this.modelBeanChange.emit(this.modelBean.selectedItems);
    };

    cancel = (skipPopupClose?: boolean) => {
        if (this.modelBean.hasCommit) {
            //if has commit, then cancel means cancel (return to init state)
            this.isInit = false;
            this.refresh();
            this.zone.run(() => {
                this.modelBean.items = S25Util.array.clone(this.modelBean.origItems);
                this.modelBean.selectedItems = this.modelBean.items.filter((item) => {
                    return item.checked;
                });
                this.modelBean.removedItems = [];
                this.modelBean.addedItems = [];
                MultiselectResultsApi.refresh(document.body, this.modelBean.uuid);
                !skipPopupClose && MultiselectPopupApi.close(document.body, this.modelBean.uuid);
                this.ngOnInit();
            });
            this.dirty = false;
        } else {
            //else it is just an alias for done()
            this.done(skipPopupClose);
        }
    };

    trackByFn(idx: any, item: any) {
        return idx;
    }

    refresh = () => {
        try {
            this.cd.detectChanges();
        } catch (error: any) {}
    };

    initComp = () => {
        this.hasItems = this.modelBean.items && this.modelBean.items.length > 0;
        this.isInit = true;
        this.refreshSelected(this.modelBean.items);
    };

    forceScrollAction = () => {
        InfiniteScrollApi.forceScrollAction(this.elementRef.nativeElement);
    };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.id = "select-" + S25MultiselectComponent.count++;
    }

    async ngOnInit() {
        this.modelBean.uuid =
            this.modelBean.uuid || "multiselect-" + Date.now() + "-" + Math.floor(Math.random() * 10000);
        this.modelBean.hasFilter = S25Util.coalesce(this.modelBean.hasFilter, true);
        this.modelBean.hasDone = S25Util.coalesce(this.modelBean.hasDone, true);
        this.modelBean.onMatchingChange = this.modelBean.onMatchingChange || function (matching: "any" | "all") {};

        //cannot cancel unless we have a commit stage, bc as items were checked actions were getting fired on those items
        if (!this.modelBean.hasCommit) {
            this.modelBean.hasCancel = false;
        }

        this.modelBean.selectedItems = this.modelBean.selectedItems || [];
        this.modelBean.uncommittedSelectedItems = this.modelBean.hasCommit
            ? S25Util.array.clone(this.modelBean.selectedItems) || []
            : [];
        this.modelBean.removedItems = this.modelBean.removedItems || [];
        this.modelBean.addedItems = this.modelBean.addedItems || [];

        jSith.forEach(this.modelBean.selectedItems, (_: any, item: S25ItemI) => {
            if (!S25Util.array.isIn(this.modelBean.items, "itemId", item.itemId)) {
                this.modelBean.items.push(item);
            }
        });

        this.refreshSelected(this.modelBean.items); //with arrays set, fill 'em up
        this.modelBean.origItems = S25Util.array.clone(this.modelBean.items);

        this.modelBean.clearSearch = this.clearSearch;
        this.modelBean.addSelectedItem = (item: S25ItemI) => {
            this.setSelected(item);
            this.sortSelectedItemsByItemName();
            //note: do NOT perform scope.action() here; some action callers fire DAO changes not appropriate for each multiselect change
            this.modelBean.addAction && this.modelBean.addAction(item); //single item added to selections, fire addAction
        };
        this.modelBean.removeSelectedItem = (item: S25ItemI) => {
            this.setUnselected(item);
            //note: do NOT perform scope.action() here; some action callers fire DAO changes not appropriate for each multiselect change
            this.modelBean.removeAction && this.modelBean.removeAction(item); //single item removed from selections, fire removeAction
        };
        this.modelBean.selectNone = () => this.selectNone(); // Needed for radio buttons
        this.modelBean.done = this.done;
        this.modelBean.cancel = this.cancel;

        const domainFilterPromise = (this.modelBean.domainFilter?.promise || jSith.when()).then(() => {
            this.dropdownModel = {
                placeholder: "Select a filter...",
                itemNameProp: "itemName",
                searchEnabled: true,
                items: this.modelBean.domainFilter && this.modelBean.domainFilter.options,
            };
        });

        this.modelBean.onInit =
            this.modelBean.onInit ||
            function () {
                return jSith.when();
            };

        domainFilterPromise.then(this.modelBean.onInit).then(() => {
            // when pre populated chosen itemId to -999, need to display items  when first popup without click on All filtered option
            if (this.modelBean.items.length === 0 && this.modelBean.domainFilter?.chosen?.itemId === -999) {
                this.modelBean.items = this.modelBean?.origItems || [];
            }

            this.refreshSelected(this.modelBean.items); //do this again as onInit may have added items
            this.initComp();
        });

        if (!this.modelBean.usePopover && this.modelBean.isSearchCriteria && !this.modelBean.domainFilter) {
            const loadingEl = this.elementRef.nativeElement.querySelector(".no-popover-spinner");
            loadingEl && S25LoadingApi.init(loadingEl);

            await this.modelBean.onInit?.();

            loadingEl && S25LoadingApi.destroy(loadingEl);
        }
    }
}
